import {
  IQueryParams,
  Prettify,
  RegionEnum,
  TServerResponse,
  TServerResponsePagination
} from './Common'

export enum SchoolStatusEnum {
  READY_TO_BE_SEEN = 'Ready to be seen',
  // eslint-disable-next-line max-len
  PENDING_DENTAL_FORMS_TO_BE_PROCESSED_AND_ASSIGNED_TO_CDBS_PROBONO = 'Pending dental forms to be processed and assigned to CDBS/Probono',
  CONTINATION_VISIT = 'Continuation visit',
  CANCELLED = 'Cancelled'
}

export enum SchoolTypeEnum {
  MIXED = 'Mixed',
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  SPECIAL = 'Special'
}

export interface ISchoolContactPeople {
  id: string
  full_name: string
  email: string
  phone: string
}

export interface ISchoolFiles {
  id: string
  file: string
  size: number
  confidential: boolean
}

interface ISchoolManager {
  id: string
  email: string
}

export interface ISchool {
  id: string
  name: string
  address: string
  email: string
  phone: string
  type: SchoolTypeEnum
  region: RegionEnum
  status: SchoolStatusEnum
  number_of_students: string
  classes: string
  admin_notes: string
  general_notes: string
  files: ISchoolFiles[]
  dental_visit_start_date: string
  dental_visit_end_date: string
  form_submission_deadline: string
  continuation_visit_date: string
  contact_people: ISchoolContactPeople[]
  manager: ISchoolManager
  value: string
  is_archived: boolean
}

// Models
// AE School

export type TAESchoolFilesModel = {
  id: string
  file: File
  name: string
  extension: string
  size: string
  created_at: string
  confidential: boolean
}

export interface ISchoolFile {
  confidential: boolean
  file_upload: string
  report: string
  id: string
  name: string
  extension: string
  size: string
  created_at: string
}

export type TAESchoolContactPeopleModel = Pick<
  ISchoolContactPeople,
  'id' | 'full_name' | 'email' | 'phone'
>

export type TAESchoolManagerModel = {
  id?: ISchoolManager['id']
} & Pick<ISchoolManager, 'email'>

export type TAESchoolModel = Pick<
  ISchool,
  'name' | 'address' | 'email' | 'phone' | 'dental_visit_start_date'
> & {
  id?: ISchool['id']
  type?: ISchool['type'] | '' | null
  region: ISchool['region'] | ''
  status?: ISchool['status'] | '' | null
  classes?: string[]
  files?: any[]
  contact_people: TAESchoolContactPeopleModel[]
  manager: TAESchoolManagerModel
} & Partial<
    Pick<
      ISchool,
      | 'number_of_students'
      | 'admin_notes'
      | 'general_notes'
      | 'dental_visit_end_date'
      | 'form_submission_deadline'
      | 'continuation_visit_date'
    >
  >

// API
// Add school

export type TGetSchoolParams = Pick<ISchool, 'id'> & {
  status?: SchoolStatusEnum
}

export type TGetSchoolResponseData = ISchool

export type TGetSchoolResponse = TServerResponse<TGetSchoolResponseData>

// Get schools

export type TGetSchoolsParams = Pick<
  IQueryParams,
  'ordering' | 'page' | 'page_size' | 'search'
> & {
  dental_visit_start_date?: string
  form_submission_deadline?: string
  status?: SchoolStatusEnum | ''
  is_archived?: boolean
  region?: string
}

export type TGetSchoolsResponseData = Pick<
  ISchool,
  | 'id'
  | 'name'
  | 'email'
  | 'contact_people'
  | 'dental_visit_start_date'
  | 'form_submission_deadline'
  | 'status'
  | 'value'
  | 'is_archived'
>

export type TGetSchoolsResponse = TServerResponse<
  TServerResponsePagination<TGetSchoolsResponseData[]>
>

export type TGetFilterSchoolsResponse = TServerResponse<
  TServerResponsePagination<Pick<TGetSchoolsResponseData, 'id' | 'name'>[]>
>

// Add school

export type TAddSchoolParams = Omit<
  TAESchoolModel,
  'id' | 'number_of_students' | 'classes'
> & {
  number_of_students?: number
  classes?: string
}

type TAddSchoolResponseData = ISchool

export type TAddSchoolResponse = TServerResponse<TAddSchoolResponseData>

// Update school

export type TPatchUpdateSchoolParams = Prettify<
  Partial<
    Omit<
      TAESchoolModel,
      'number_of_students' | 'classes' | 'files' | 'contact_people' | 'manager'
    >
  > & {
    number_of_students?: number
    classes?: string
    manager?: { email: string }
    isSchoolCancelled?: boolean
  }
>

type TPatchUpdateSchoolResponseData = ISchool

export type TUpdateSchoolResponse =
  TServerResponse<TPatchUpdateSchoolResponseData>

// Delete school

export type TDeleteSchoolParams = Pick<TAESchoolModel, 'id'>

type TDeleteSchoolResponseData = void

export type TDeleteSchoolResponse = TServerResponse<TDeleteSchoolResponseData>

export interface TGetSchoolClasses {
  id: string
  classes: string
}

export type TGetSchoolClassesResponse = TServerResponse<TGetSchoolClasses>
