import {
  autocompleteClasses,
  backdropClasses,
  buttonClasses,
  checkboxClasses,
  chipClasses,
  formControlClasses,
  formLabelClasses,
  inputAdornmentClasses,
  inputBaseClasses,
  listClasses,
  menuItemClasses,
  outlinedInputClasses,
  paginationClasses,
  paginationItemClasses,
  paperClasses,
  selectClasses,
  touchRippleClasses
} from '@mui/material'
import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  Shadows
} from '@mui/material/styles'
import { createBreakpoints } from '@mui/system'

import { DISABLED_ELEMENT_OPACITY } from './utils/constants'

declare module '@mui/material/styles' {
  interface Palette {
    grey100: PaletteColor
    grey200: PaletteColor
    richElectricBlue: PaletteColor
  }
  interface PaletteOptions {
    grey100: PaletteColorOptions
    grey200: PaletteColorOptions
    richElectricBlue: PaletteColorOptions
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    point_1200: true
    point_894: true
    xxl: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey200: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    grey100: true
    richElectricBlue: true
  }
}

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 769,
    point_894: 894,
    lg: 1024,
    point_1200: 1200,
    xl: 1350,
    xxl: 1500
  }
})

export const theme = createTheme({
  palette: {
    primary: {
      main: '#32B22B',
      dark: '#278F22',
      light: '#C2E8C0'
    },
    secondary: {
      main: '#7B8A99',
      light: '#DCF6DA'
    },
    grey100: {
      main: '#F8F9FB',
      contrastText: '#F8F9FB'
    },
    grey200: {
      main: '#F0F3F7',
      contrastText: '#F0F3F7'
    },
    richElectricBlue: {
      main: '#00A1D5',
      contrastText: '#00A1D5'
    },
    text: {
      primary: '#010800',
      secondary: '#1C211B'
    },
    common: {
      white: '#FFFFFF'
    },
    warning: {
      main: '#FF9900',
      light: '#FFD699',
      '100': '#FFF2E0'
    },
    error: {
      main: '#A90000',
      light: '#FBEBEB'
    },
    grey: {
      '100': '#F8F9FB',
      '200': '#F0F3F7',
      '300': '#E0E4E9'
    }
  },
  typography: {
    fontFamily: ['Figtree', 'Inter', 'sans-serif'].join(','),
    h1: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%'
    },
    h2: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%'
    },
    h3: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '26px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%'
    },
    h4: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%'
    },
    h5: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%'
    },
    h6: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%'
    },
    button: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%'
    },
    body1: {
      color: '#1C211B',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '21px'
    },
    body2: {
      color: '#1C211B',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px'
    },
    subtitle1: {
      color: '#010800',
      fontFamily: 'Figtree',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '21px'
    },
    subtitle2: {
      color: '#010800',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%'
    },
    caption: {
      color: '#7B8A99',
      fontFamily: 'Figtree',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%',
      textTransform: 'uppercase'
    }
  },
  shadows: [
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 1 ? '0px 4px 12px 0px rgba(224, 228, 233, 0.40)' : shadow
    )
  ] as Shadows,
  breakpoints: {
    values: breakpoints.values
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          height: 'fit-content'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true
      },
      variants: [
        {
          props: {
            maxWidth: 'xl'
          },
          style: {
            [breakpoints.up('xl')]: {
              maxWidth: breakpoints.values.xl + 44 * 2
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          padding: '0 44px'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [
        {
          props: {
            variant: 'contained'
          },
          style: {
            padding: '8px 32px',
            borderRadius: '4px'
          }
        },
        {
          props: {
            variant: 'contained',
            color: 'secondary'
          },
          style: {
            background: '#F0F3F7',
            padding: '8px 32px',
            borderRadius: '4px',
            color: '#010800',
            fontFamily: 'Figtree',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '150%',
            '&:hover': {
              backgroundColor: '#F0F3F7'
            }
          }
        },
        {
          props: {
            variant: 'contained',
            color: 'primary'
          },
          style: {
            backgroundColor: '#32B22B',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#278F22'
            },
            '&:disabled': {
              backgroundColor: '#DCF6DA',
              color: '#7B8A99'
            }
          }
        },
        {
          props: {
            variant: 'contained',
            color: 'grey200'
          },
          style: {
            backgroundColor: '#F0F3F7',
            color: '#010800',
            '&:hover': {
              backgroundColor: '#E0E4E9'
            },
            '&:disabled': {
              backgroundColor: '#F8F9FB',
              color: '#7B8A99'
            }
          }
        },
        {
          props: {
            variant: 'link',
            color: 'primary'
          },
          style: {
            padding: 'unset',
            color: '#32B22B',
            fontSize: '14px',
            lineHeight: '16.8px',
            '&:hover': {
              backgroundColor: 'unset'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          gap: '4px',
          fontFamily: 'Figtree',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          textTransform: 'none',
          [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
            margin: 'unset'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'inline-flex',
          marginBottom: '4px',
          lineHeight: '21px',
          fontWeight: 500,
          [`&.${formLabelClasses.focused}`]: {
            color: '#32B22B'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 'unset',
          marginTop: '4px',
          color: '#A90000',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 400,
          textTransform: 'none'
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: {
            variant: 'outlined',
            color: 'primary'
          },
          style: {
            [`.${inputBaseClasses.root}`]: {
              borderRadius: '3px',
              [`.${inputBaseClasses.input}`]: {
                height: 'auto',
                color: '#010800',
                '&::placeholder': {
                  color: '#7B8A99'
                }
              },
              [`.${outlinedInputClasses.notchedOutline}`]: {
                border: '1px solid',
                borderColor: '#E0E4E9'
              },
              '&:hover': {
                [`.${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: '#32B22B'
                }
              },
              [`&.${inputBaseClasses.focused}`]: {
                [`.${outlinedInputClasses.notchedOutline}`]: {
                  borderWidth: '1px',
                  borderColor: '#32B22B'
                }
              },
              [`&.${inputBaseClasses.error}`]: {
                [`.${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: '#A90000'
                }
              },
              [`&.${inputBaseClasses.disabled}`]: {
                [`.${outlinedInputClasses.notchedOutline}`]: {
                  border: '1px solid',
                  borderColor: '#F8F9FB'
                }
              }
            }
          }
        },
        {
          props: {
            variant: 'standard'
          },
          style: {
            [`.${inputBaseClasses.root}`]: {
              [`.${inputBaseClasses.input}`]: {
                height: 'auto',
                color: '#010800',
                '&::placeholder': {
                  color: '#7B8A99'
                }
              }
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          [`.${inputBaseClasses.root}`]: {
            [`.${inputAdornmentClasses.root}`]: {
              svg: {
                width: '20px',
                height: '20px'
              }
            },
            backgroundColor: '#FFFFFF',
            fontFamily: 'Inter',
            color: '#010800',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
            [`&.${inputBaseClasses.multiline}`]: {
              padding: 'unset',
              [`& .${inputBaseClasses.inputMultiline}`]: {
                paddingTop: '5px',
                paddingBottom: '5px'
              }
            },
            [`&.${inputBaseClasses.disabled}`]: {
              [`& .${inputAdornmentClasses.root}`]: {
                opacity: DISABLED_ELEMENT_OPACITY,
                pointerEvents: 'none'
              }
            },
            [`.${inputBaseClasses.input}`]: {
              padding: '8px 12px !important',
              '&::placeholder': {
                opacity: 1
              },
              '&:-webkit-autofill': {
                WebkitTextFillColor: '#000000',
                transition: 'background-color 5000s ease-in-out 0s'
              }
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`.${autocompleteClasses.endAdornment}`]: {
            svg: {
              width: '20px',
              height: '20px'
            }
          },
          [`& .${formControlClasses.root}`]: {
            [`& .${inputBaseClasses.root}`]: {
              padding: 'unset',
              [`& .${chipClasses.root}`]: {
                margin: '8px',
                marginRight: 'unset',
                borderRadius: '4px',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                [`& .${chipClasses.label}`]: {
                  padding: '4px'
                }
              }
            }
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: () => null,
        MenuProps: {
          sx: {
            [`& .${backdropClasses.root}`]: {
              backgroundColor: 'unset'
            }
          }
        }
      },
      variants: [
        {
          props: {
            variant: 'outlined',
            color: 'primary'
          },
          style: {
            minHeight: '40px',
            backgroundColor: 'unset',
            overflow: 'auto !important',
            maxHeight: '300px',
            '& .MuiPaper-root, .MuiList-root': {
              backgroundColor: 'unset',
              overflow: 'auto !important',
              maxHeight: '300px'
            },
            [`.${outlinedInputClasses.notchedOutline}`]: {
              border: '1px solid',
              borderColor: '#E0E4E9'
            },
            '&:hover': {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#32B22B'
              }
            },
            [`&.${inputBaseClasses.focused}`]: {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: '1px',
                borderColor: '#32B22B'
              }
            },
            [`&.${inputBaseClasses.error}`]: {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#A90000'
              }
            },
            [`&.${inputBaseClasses.disabled}`]: {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                border: '1px solid',
                borderColor: '#F8F9FB'
              }
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF !important',

          [`& .${selectClasses.select}`]: {
            backgroundColor: '#FFFFFF',
            padding: '8.5px 12px !important'
          },
          [`.${selectClasses.icon}`]: {
            width: '24px',
            height: '24px'
          },
          minWidth: 202,
          minHeight: 40
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#FFFFFF'
        },
        tooltip: {
          padding: '12px',
          color: '#1C211B',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '18px',
          background: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px 0px rgba(224, 228, 233, 0.40)'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          gap: '4px',
          margin: 'unset'
        }
      }
    },
    MuiCheckbox: {
      variants: [
        {
          props: {
            color: 'primary'
          },
          style: {
            '&:hover': {
              backgroundColor: '#DCF6DA'
            },
            [`&.${checkboxClasses.checked}`]: {
              backgroundColor: '#FFFFFF'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          width: '18px',
          height: '18px',
          border: '1px solid',
          borderColor: '#32B22B',
          borderRadius: '4px'
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: {
            color: 'primary'
          },
          style: {
            backgroundColor: '#DCF6DA',
            color: '#32B22B'
          }
        },
        {
          props: {
            color: 'secondary'
          },
          style: {
            backgroundColor: '#7B8A9933',
            color: '#7B8A99'
          }
        },
        {
          props: {
            color: 'warning'
          },
          style: {
            backgroundColor: '#FFF2E0',
            color: '#FF9900'
          }
        },
        {
          props: {
            color: 'error'
          },
          style: {
            backgroundColor: '#FBEBEB',
            color: '#A90000'
          }
        },
        {
          props: {
            color: 'grey100'
          },
          style: {
            backgroundColor: '#F8F9FB',
            color: '#010800'
          }
        },
        {
          props: {
            color: 'richElectricBlue'
          },
          style: {
            backgroundColor: '#BFEDFD',
            color: '#00A1D5'
          }
        }
      ],
      styleOverrides: {
        root: {
          gap: '8px',
          height: '23px',
          fontFamily: 'Figtree',
          fontSize: '10px',
          fontWeight: 600,
          lineHeight: '15px',
          textTransform: 'uppercase',
          textOverflow: 'ellipsis',
          [`& .${chipClasses.label}`]: {
            padding: '4px 8px'
          },
          '&:active': {
            boxShadow: 'unset'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(123, 138, 153, 0.20)'
        }
      }
    },
    MuiTableCell: {
      defaultProps: {
        padding: 'none'
      },
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          padding: '8px',
          borderColor: '#F0F3F7'
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          [breakpoints.down('md')]: {
            width: '100%'
          },
          [`& .${paginationClasses.ul}`]: {
            [breakpoints.down('md')]: {
              ['& li:first-of-type']: {
                marginRight: 'auto',
                [`& .${paginationItemClasses.root}`]: {
                  marginLeft: 'unset'
                }
              },
              ['& li:last-of-type']: {
                marginLeft: 'auto',
                [`& .${paginationItemClasses.root}`]: {
                  marginRight: 'unset'
                }
              }
            }
          }
        }
      }
    },
    MuiPaginationItem: {
      variants: [
        {
          props: {
            variant: 'text'
          },
          style: {
            backgroundColor: '#FFFFFF',
            borderColor: '#E0E4E9',
            color: '#32B22B',
            [`&.${paginationItemClasses.selected}`]: {
              backgroundColor: '#32B22B',
              borderColor: '#32B22B',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#32B22B'
              }
            }
          }
        },
        {
          props: {
            variant: 'text',
            shape: 'rounded'
          },
          style: {
            borderRadius: '4px',
            ['&:not([type="button"])']: {
              borderRadius: '4px'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          height: '36px',
          minWidth: '30px',
          margin: '0 4px',
          padding: '8px 12px',
          border: '1px solid',
          fontFamily: 'Figtree',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '21px',
          ['&:not([type="button"])']: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          margin: 'unset',
          [`&.${inputAdornmentClasses.positionStart}`]: {
            marginRight: '-4px'
          },
          [`&.${inputAdornmentClasses.positionEnd}`]: {
            marginLeft: '-4px'
          }
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          [`& .${paperClasses.root}`]: {
            overflow: 'visible',
            [`& .${listClasses.root}`]: {
              padding: '8px',
              overflow: 'auto',
              maxHeight: '40vh'
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '12px',
          borderRadius: '4px',
          fontSize: '16px',
          lineHeight: '24px',
          '&:hover': {
            backgroundColor: '#DCF6DA',
            color: '#32B22B'
          },
          [`& .${touchRippleClasses.root} .${touchRippleClasses.child}`]: {
            backgroundColor: '#32B22B'
          },
          [`&.${menuItemClasses.focusVisible}`]: {
            backgroundColor: '#DCF6DA'
          }
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          [`&.${autocompleteClasses.popper}`]: {
            [`& .${autocompleteClasses.paper}`]: {
              marginTop: '4px',
              padding: '8px 4px 8px 8px',
              borderRadius: '4px !important',
              border: '1px solid  #F0F3F7 !important',
              backgroundColor: '#FFF !important',
              boxShadow:
                '0px 4px 12px 0px rgba(224, 228, 233, 0.40) !important',
              [`& .${autocompleteClasses.listbox}`]: {
                // padding: '8px',
                padding: 0,
                paddingRight: '4px',
                '&::-webkit-scrollbar': {
                  width: '4px',
                  right: '20px'
                },
                '&::-webkit-scrollbar-track': {
                  WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  backgroundColor: '#F0F3F7',
                  marginRight: '4px',
                  borderRadius: '100px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#32B22B',
                  borderRadius: '100px',
                  cursor: 'pointer'
                },
                [`& .${autocompleteClasses.option}`]: {
                  padding: '12px',
                  borderRadius: '4px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  '&:hover': {
                    backgroundColor: '#DCF6DA',
                    color: '#32B22B'
                  }
                }
              }
            }
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          ['&.MuiMenu-paper']: {
            marginTop: '4px',
            borderRadius: '4px !important',
            border: '1px solid  #F0F3F7 !important',
            backgroundColor: '#FFF !important',
            boxShadow: '0px 4px 12px 0px rgba(224, 228, 233, 0.40) !important',
            '&::-webkit-scrollbar': {
              width: '4px',
              right: '20px'
            },
            '&::-webkit-scrollbar-track': {
              WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              backgroundColor: '#F0F3F7',
              marginRight: '4px',
              borderRadius: '100px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#32B22B',
              borderRadius: '100px',
              cursor: 'pointer'
            }
          },
          borderRadius: '12px',
          [`& .${paperClasses.outlined}`]: {
            border: '1px solid #F0F3F7'
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiSvgIcon-root': {
            width: '20px'
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '65px'
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: '#C2E8C0'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          ['& .MuiDrawer-paper']: {
            borderRadius: 0,
            boxShadow: 'none'
          }
        }
      }
    }
  }
})
