import {
  Box,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  selectClasses,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  BurgerMenuIcon,
  CrossIcon,
  DrawerHandleIcon,
  DropdownIcon,
  Logo
} from '@/assets/icons'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useDefaultPage } from '@/hooks/useDefaultPage'
import { setRegion } from '@/redux/features/preferenceSlice'
import {
  menuPrimaryLinks,
  settingsLink,
  showNavigationMenuPages
} from '@/routes/pages-routes'
import { theme } from '@/theme'
import { RegionEnum } from '@/types'
import { SIDEBAR_DRAWER_WIDTH } from '@/utils/constants'
import { regionOptions } from '@/utils/constants/lists'
import { canI, ERole } from '@/utils/permissions/permissions'

import PrivatePagesHeaderProfileMenu from './PrivatePagesHeaderProfileMenu'

interface Props {
  openDrawer: boolean
  sidebar?: boolean
  handleDrawer: () => void
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  ...(open && {
    width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`
  }),
  ...(!open && {
    width: 'calc(100% - 103px)'
  }),
  maxHeight: '80px',
  height: '80px',
  borderLeft: 'unset',
  borderRight: 'unset',
  borderRadius: 'unset'
}))

const PrivatePagesHeader: React.FC<Props> = (props): JSX.Element => {
  const { openDrawer, sidebar, handleDrawer } = props

  const dispatch = useAppDispatch()

  const school__region = useAppSelector((state) => state.preferenceStore.region)

  const me = useAppSelector((state) => state.userStore.me)
  const role = me?.role as ERole

  const isMeSchool = role === ERole.School

  const { navigateToDefaultPage } = useDefaultPage()

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)

  const isDown894 = useMediaQuery(theme.breakpoints.down('point_894'))

  const navigate = useNavigate()
  const location = useLocation()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget)
  }

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(
      setRegion(
        event.target.value.toLowerCase() as Lowercase<keyof typeof RegionEnum>
      )
    )
  }

  const isForceShowMenuIcon = showNavigationMenuPages.find((l) =>
    location.pathname.includes(l)
  )

  const isMenuIconShown = isForceShowMenuIcon || isDown894

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        bgcolor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey200.main}`,
        px: '45px',
        display: 'flex',
        justifyContent: 'center',
        py: 2,
        width: {
          xs: '100%',
          point_894: sidebar
            ? openDrawer
              ? `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`
              : 'calc(100% - 103px)'
            : '100%'
        }
      }}
      open={openDrawer}
    >
      {sidebar && (
        <IconButton
          sx={{
            padding: 0,
            display: { xs: 'none', point_894: 'flex' },
            position: 'absolute',
            left: -12,
            top: 28
          }}
          aria-label="sidebar-handle"
          onClick={handleDrawer}
        >
          <DrawerHandleIcon
            open={openDrawer}
            sx={{
              width: '24px',
              height: '24px'
            }}
          />
        </IconButton>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" flexGrow={1} gap="44px">
          {(!sidebar || isDown894) && (
            <Box
              py="20px"
              minHeight="80px"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigateToDefaultPage()}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                gap="10px"
                height="35px"
              >
                <Logo
                  sx={{
                    width: '44px',
                    height: '40px'
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    width: '88px'
                  }}
                >
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                      width: '88px'
                    }}
                    width="100%"
                    maxWidth="88px"
                    variant="h6"
                  >
                    Australian Dental Health
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {!isMeSchool && (
            <FormControl
              fullWidth
              sx={{
                maxWidth: '200px'
              }}
            >
              <Select
                sx={{
                  p: 0,
                  [`.${selectClasses.icon}`]: {
                    width: '24px',
                    height: '24px'
                  }
                }}
                variant="outlined"
                value={school__region}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={DropdownIcon}
              >
                {regionOptions.map((r) => (
                  <MenuItem key={r} value={r.toLowerCase().replace(/ /g, '_')}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box display="flex">
          <Box
            borderLeft={`1px solid ${theme.palette.grey[200]}`}
            sx={{
              paddingLeft: '20px',
              paddingRight: isMenuIconShown ? '20px' : '0',
              marginLeft: '20px',
              marginRight: isMenuIconShown ? '20px' : '0',
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              borderRight: isMenuIconShown
                ? `1px solid ${theme.palette.grey[200]}`
                : 'none'
            }}
          >
            <PrivatePagesHeaderProfileMenu />
          </Box>
          {isMenuIconShown && (
            <Box
              display={{
                xs: 'flex',
                point_894: isForceShowMenuIcon ? 'flex' : 'none'
              }}
            >
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  p: 0,
                  display: {
                    xs: 'flex',
                    point_894: isForceShowMenuIcon ? 'flex' : 'none'
                  },
                  borderRadius: '4px'
                }}
                aria-label="menu"
              >
                {anchorMenu ? (
                  <CrossIcon
                    sx={{
                      width: '24px',
                      height: '24px'
                    }}
                  />
                ) : (
                  <BurgerMenuIcon
                    sx={{
                      width: '24px',
                      height: '24px'
                    }}
                  />
                )}
              </IconButton>
              <Menu
                sx={{ mt: '45px', minWidth: '260px' }}
                id="menu-appbar"
                anchorEl={anchorMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorMenu)}
                onClose={() => setAnchorMenu(null)}
              >
                {[...menuPrimaryLinks, settingsLink]
                  .filter((link) => canI(role, link.permissionName))
                  .map((l) => (
                    <MenuItem
                      sx={{
                        '&:hover': { background: 'transparent' },
                        minWidth: '260px',
                        width: '100%',
                        py: '2px',
                        px: 0,
                        display: {
                          xs: 'flex',
                          point_894: isForceShowMenuIcon ? 'flex' : 'none'
                        }
                      }}
                      key={l.name}
                      onClick={() => {
                        navigate(
                          l.name === 'School information'
                            ? `${l.link}/${me?.id}`
                            : l.link
                        )
                        setAnchorMenu(null)
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '12px',
                          width: '100%',
                          backgroundColor:
                            location.pathname === l.link
                              ? theme.palette.secondary.light
                              : '',
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.light,
                            borderRadius: '4px',
                            cursor: 'pointer'
                          }
                        }}
                      >
                        <ListItemIcon>
                          <l.icon
                            sx={{
                              width: '20px',
                              height: '20px'
                            }}
                            fill={
                              location.pathname === l.link
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={l.name}
                          primaryTypographyProps={{
                            color:
                              location.pathname === l.link
                                ? theme.palette.primary.main
                                : 'text',
                            variant: 'subtitle1'
                          }}
                        />
                      </Box>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          )}
        </Box>
      </Box>
    </AppBar>
  )
}

export default PrivatePagesHeader
